<template>
    <!-- 首页btn 【ENTRANCE_BANNER】 -->
    <div class="btns">
        <button
            class="btn"
            v-for="item in moduleInfo.bannerList"
            :key="item.key"
            @click="btnClk(item)"
        >
            <img class="btn-icon" :src="item.image | compress(48, true)" alt="" />
            {{ item.title }}
        </button>
    </div>
</template>
<script>
import jump from '@/views/Index/jump.js';
export default {
    props: {
        moduleInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods: {
        btnClk(item) {
            this.sa('CommonBtnClk', {
                uid: this.$store.state.userInfo.uid,
                CurPage: this.$store.state.gender === 0 ? '男生页' : '女生页',
                PrePage: '',
                ButtonName: item.title,
            });
            jump(item.actionType, { query: { a: 1 } });
        },
    },
};
</script>
<style scoped lang="less">
@import '~@/assets/vws.less';
.btns {
    display: flex;
    margin: 20px 0 0;
    padding: 0;
    .vws(padding-left, 24px);
    .vws(padding-right, 24px);
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.4%;
    height: 2.86em;
    font-size: 14px;
    font-weight: 700;
    border-radius: 6px;
    color: #fff;
    background: #1f2035;
    flex: 1;
}
.btn:last-child {
    margin-right: 0;
}
.btn-icon {
    margin-right: 8px;
    width: 1.71em;
    height: 1.71em;
}
</style>
