<template>
    <div class="six-modules">
        <div class="module-title">{{ moduleInfo.title }}</div>
        <div class="comics-list">
            <Comic class="comic-module" v-for="comic in comics" :key="comic.id" :comic="comic" />
        </div>
    </div>
</template>
<script>
import Comic from './Comic.vue';
export default {
    data: function () {
        return {};
    },
    components: {
        Comic,
    },
    props: {
        moduleInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        comics() {
            return this.moduleInfo.bannerList;
        },
    },
};
</script>
<style scoped lang="less">
@import '~@/assets/vws.less';

.six-modules {
    padding: 0;
    .vws(padding-left, 24px);
    .vws(padding-right, 24px);
}
.module-title {
    padding: 12px 0;
    font-size: 17px;
    font-weight: 700;
    color: #fff;
}
.comics-list {
    font-size: 0;
}
.comic-module {
    .vws(width, 224px);
    .vws(height, 470px);
    .vws(margin-right, 15px);
    .vws(margin-bottom, 20px);
}
.comic-module:nth-child(3n) {
    margin-right: 0;
}
</style>
