<template>
    <div class="single-comic" @click="comicClick()">
        <div class="comic-img">
            <img v-lazyLoad :data-src="comic.image | compress(336)" alt="" />
            <ComicLabel v-if="showLabel" />
        </div>
        <div class="comic-intr">
            <div class="comic-title">{{ comic.title }}</div>
        </div>
    </div>
</template>
<script>
import jump from '@/views/Index/jump.js';
import ComicLabel from '@/components/label/index.vue';
import { mapGetters } from 'vuex';

export default {
    data: function () {
        return {};
    },
    props: {
        comic: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: {
        ComicLabel,
    },
    computed: {
        ...mapGetters(['canFeelVip']),
        showLabel() {
            return this.canFeelVip && this.comic.vipFree;
        },
    },
    methods: {
        comicClick() {
            jump(this.comic.actionType, {
                params: {
                    SourceModule: this.$parent.moduleInfo.title,
                    TriggerPage: this.$store.state.gender === 0 ? '男生页' : '女生页',
                },
            });
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.single-comic {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
}
.comic-img {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 2px;
    }
}
.comic-intr {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    .vws(height, 122px);
}
.comic-title {
    display: -webkit-box;
    overflow: hidden;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
    color: #fff;
    vertical-align: bottom;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    .vws(padding-left, 10px);
    .vws(padding-right, 10px);
    .vws(margin-bottom, 16px);
}
</style>
